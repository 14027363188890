@import "../../styles/colors.css";
@import "../../styles/borders.css";
@import "../../styles/layout.css";
@import "../../styles/viewports.css";

.container__hidden {
  display: none;
}

.group {
  padding-left: var(--small-screen-side-padding);
}

.header {
  composes: london3 from "~styles/typography/styles.css";
  text-transform: uppercase;
  color: var(--grey-90);
  display: none;
}

@media (--medium-viewport) {
  .container {
    background-color: var(--grey-20);
  }

  .body {
    padding: 30px var(--medium-screen-side-padding) 0;
  }

  .group {
    padding: 0 4px;
    border-top: var(--navigation-border);
  }
}

@media (--large-viewport) {
  .body {
    max-width: var(--large-screen-max-content-width);
    padding: var(--large-screen-side-padding);
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
  }

  .group {
    border-top: none;
    margin-left: 18px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .group > * {
    margin-bottom: 10px;
  }

  .header {
    display: block;
  }
}
