/* to be resolved in AB#859700 */
/* stylelint-disable property-disallowed-list */

@import "../../../styles/viewports.css";
@import "../../../styles/colors.css";

.internationalSites {
  background-color: var(--grey-10);
  padding: 8px 0;
}

.internationalSites a {
  color: var(--grey-40);
  padding: 10px;
  width: 24px;
  height: 24px;
  display: inline-block
}

.internationalSites a:hover {
  color: var(--asos-blue);
}

.internationalSites p {
  color: var(--grey-90);
  margin: 0 16px;

  /* leavesden4 */
  font-size: 12px;
  letter-spacing: 0.8px;
  line-height: 16px;
  font-weight: 400;
}

.internationalSites ul {
  margin: 0 4px;
}

.internationalSites li {
  display: inline-block;
}

.internationalSites img {
  width: 100%;
  height: 100%;
}

@media (--medium-viewport) {
  .internationalSites {
    background-color: transparent;
    padding: 0;
  }

  .internationalSites a {
    padding: 14.5px;
    width: 15px;
    height: 15px;
  }

  .internationalSites > * {
    display: inline;
    vertical-align: middle;
  }

  .internationalSites p {
    margin: 0;

    /* leavesden3 */
    font-size: 14px;
    letter-spacing: 0.6px;
    line-height: 20px;
  }
}

@media (--large-viewport) {
  .internationalSites > * {
    display: block;
  }

  .internationalSites ul {
    margin: 0;
  }
}
