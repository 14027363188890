/* to be resolved in AB#859700 */
/* stylelint-disable property-disallowed-list */

@import "../../../styles/colors.css";
@import "../../../styles/icons/variables.css";
@import "../../../styles/layout.css";
@import "../../../styles/typography/variables.css";
@import "../../../styles/viewports.css";

.container {
  background-color: var(--grey-10);
}

.container[open] {
  border-bottom: 1px solid var(--grey-40);
}

.header {
  composes: bodyText from "~styles/typography/styles.css";
  position: relative;
  display: block;
  background-color: var(--grey-20);
  line-height: 44px;
  border-bottom: 1px solid var(--grey-40);
  padding-left: var(--small-screen-side-padding);
  cursor: default;
}

.header__focused {
  outline: none;
  box-shadow: none;
}

.header::-webkit-details-marker {
  display: none;
}

.header:last-child {
  border-bottom: none;
}

.header::after {
  content: " ";
  background-image: svg-inline(plus);
}

.header::after,
.container > .header::after {
  position: absolute;
  top: calc(50% - 5px);
  right: var(--small-screen-side-padding);
  height: 10px;
  width: 10px;
  background-position: center;
}

.container[open] > .header::after {
  content: " ";
  background-image: svg-inline(minus);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  details.container > summary.header::before {
    display: none;
  }
}

.link {
  composes: bodyText from "~styles/typography/styles.css";
  composes: asosFocus from "~styles/accessibility.css";
  display: block;
  line-height: 44px;
  padding-left: var(--small-screen-side-padding);
  text-decoration: none;
  color: var(--grey-90);
  background-color: var(--grey-20);
}

.link:hover, .link:focus {
  color: var(--blue);
}

@media (--medium-viewport) {
  .container {
    display: inline-block;
    width: calc((100% - 80px) / 3);
    margin-right: 40px;
    margin-bottom: var(--medium-screen-side-padding);
    vertical-align: top;
    background-color: var(--grey-20);
  }

  .container:first-child {
    margin-left: 0;
  }

  .container[open] {
    border-bottom: none;
  }

  .container:last-of-type {
    margin-right: 0;
  }

  .header {
    color: var(--grey-90);
    border-bottom: none;
    padding-bottom: 10px;
    padding-left: 0;
    margin: 0;
    display: block;
    line-height: normal;
    font-size: var(--small-font-size);
    font-family: var(--primary-font);
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 900;
  }

  .header::after,
  .container[open] > .header::after {
    content: none;
  }

  .link {
    line-height: 30px;
    padding: 0 5px;
    margin: 0 -5px;
  }
}

@media (--large-viewport) {
  .container {
    flex: 1;
    margin-left: 16px;
    margin-right: 0;
    margin-bottom: 0;
  }

  .header {
    /*
    this would have been composes: h4Text, but composes doesn't work with media queries (it gets applied immediately)
    */
    font-size: var(--small-font-size);
    font-family: var(--primary-font);
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 900;
  }
}
