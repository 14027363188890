@import "../../styles/colors.css";
@import "../../styles/viewports.css";

.wrapper {
  position: relative;
  touch-action: none;
  z-index: 10;
  outline: none;

  --transitionDurationValue: 600;
  --transitionDuration: calc(var(--transitionDurationValue) * 1ms);
  --transitionDelayValue: 250;
  --transitionDelay: calc(var(--transitionDelayValue) * 1ms);
}

.outerWrapper {
  position: absolute;
  overflow: hidden;
  top: 50px;
  width: 325px;
  transition: max-height var(--transitionDuration);
  transition-delay: var(--transitionDelay);
  max-height: 0;
}

.outerWrapper::before {
  content: "";
  width: 0;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 11px solid var(--grey-20);
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  transition: opacity var(--transitionDuration);
  transition-delay: var(--transitionDelay);
  z-index: 1;
}

.slidedown {
  background-color: var(--white);
  border: 1px solid var(--grey-40);
  box-shadow: -2px 2px 4px 0 rgba(var(--asos-black-rgb), 0.14);
  transform: translateY(calc(-100% - 5px));
  transition: transform var(--transitionDuration);
  transition-delay: var(--transitionDelay);
  display: flex;
  flex-direction: column;
}

.wrapper__open .outerWrapper::before {
  opacity: 1;
}

.wrapper__open .slidedown {
  transform: translateY(0);
}

.wrapper__hidden .outerWrapper,
.wrapper__hidden .slidedown {
  visibility: hidden;
}

.wrapper__open .outerWrapper {
  max-height: 100vh;
}

:global(.headroom--unpinned.headroom--scrolled) .wrapper__open .outerWrapper {
  top: 100px;
}

:global(#myAccountDropdown) .outerWrapper {
  left: -175px;
}

:global(#miniBagDropdown) .outerWrapper {
  left: -275px;
}

:global(#myAccountDropdown) .outerWrapper::before {
  left: 185px;
}

:global(#miniBagDropdown) .outerWrapper::before {
  left: 285px;
}

.innerWrapper {
  left: 0;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
  right: 0;
  top: 10px;
  display: flex;
  flex-direction: column;
}

.content {
  display: none;
  flex-direction: column;
  min-height: 255px;
  max-height: calc(100vh - 200px);
}

.content__displayed {
  display: flex;
}

.body {
  background-color: var(--white);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  flex-shrink: 1;
}

.header {
  background-color: var(--grey-20);
  box-sizing: border-box;
  display: flex;
  height: 50px;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
  flex-shrink: 0;
}

.footer {
  display: flex;
  background-color: var(--grey-20);
  flex-shrink: 0;
  z-index: 1;
}

@media (--medium-viewport) {
  .container {
    display: initial;
  }
}
